// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breeze .show-mini-sidebar .main-content {
  margin-left: 40px;
}

app-side-nav {
  position: absolute;
}

.sidebar {
  flex-basis: 200px;
  border-bottom: 0 none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
  z-index: 10;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.sidebar .wfm-sidebar-menu-container li {
  border-left: 5px solid #B8C4CC;
  padding-left: 16px;
  line-height: 42px;
}
.sidebar .wfm-sidebar-menu-container li.nav-item {
  background-color: #F2F5F7;
  height: 44px;
}
.sidebar .wfm-sidebar-menu-container li.nav-item a {
  padding-top: 0;
  padding-bottom: 0;
  color: #000000;
}
.sidebar .wfm-sidebar-menu-container li.nav-item a span {
  margin-left: 10px;
  display: inline-block;
}
.sidebar .wfm-sidebar-menu-container li.nav-item a.active svg {
  fill: #008CCB;
}
.sidebar .wfm-sidebar-menu-container li.nav-item a.active .item-label {
  color: #0094D1;
}
.sidebar.show-mini-sidebar {
  flex-basis: 60px;
}
.sidebar.show-mini-sidebar .wfm-sidebar-menu-container li {
  padding-left: 16px;
}
.sidebar.show-mini-sidebar .wfm-sidebar-menu-container li.nav-item .item-label {
  display: none;
}

.top-bar {
  background-image: linear-gradient(180deg, #009EDA, #007CBE);
  padding: 0px 20px;
  line-height: 34px;
}
.top-bar .app-name {
  vertical-align: middle;
  display: inline-block;
  color: white;
  font-size: 18px;
  font-family: "Open Sans Regular", "Helvetica Neue", sans-serif;
}

.nav-item.show,
.nav-item:hover {
  background: #007fba;
}

.nav-item.dropdown {
  font-size: 14px;
}

.dropdown-menu {
  border-radius: 0;
  margin-top: 0;
  width: 180px;
  padding: 2px 0 0 0;
  border: 0;
  font-size: 14px;
  background: #006fa1;
  color: #FFF;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
}
.dropdown-menu .dropdown-item {
  background: #007fba;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dropdown-menu .dropdown-item:hover {
  background: #1C639C;
}
.dropdown-menu .dropdown-item.disabled {
  color: lightgrey;
  opacity: 0.65;
}

.navbar-dark .navbar-nav .nav-link {
  color: #FFF;
  padding-left: 21px;
  padding-right: 37px;
}
.navbar-dark .navbar-nav .nav-link .dropdown-item:hover {
  background: #1C639C;
}

.show.nav-item.dropdown {
  width: 180px;
}

.show .dropdown-toggle {
  text-align: left;
}

.dropdown-toggle span {
  max-width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
}

.dropdown-toggle::after {
  vertical-align: middle;
  border: 0;
  font-size: 10px;
  content: "\\e918";
  font-family: "icomoon";
  position: absolute;
  top: 8px;
  right: 20px;
}

.navbar-brand {
  padding-left: 24px;
}

.app-content {
  overflow: hidden;
}

.main-content {
  background-color: #E1EAF2;
  position: relative;
  margin-left: 0px;
}

.with-top-bar .main-content {
  padding-left: 230px;
  padding-right: 0;
  margin-left: 0;
  transition: all 0.2s ease-in-out 0s;
}

.with-top-bar.show-mini-sidebar .main-content {
  margin-left: 0px;
  padding-left: 60px;
  padding-right: 0;
}

.content-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.content-wrapper.with-top-bar {
  top: 50px;
}

.help-icon {
  color: white;
  font-size: 20px;
  padding-top: 8px;
  cursor: pointer;
}

.dropdown-user a {
  color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
