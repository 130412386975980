// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  min-height: 100%;
  background-attachment: fixed;
  background-image: url("/assets/images/background-pattern.svg"), linear-gradient(rgb(0, 158, 218), rgb(0, 124, 190));
  height: 100%;
  overflow: auto;
}

.login-container .container {
  padding-bottom: 50px;
}

#logo {
  background-image: url("/assets/images/nice-logo-black-with-white.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 76px 30px;
  margin: 50px auto 18px;
  padding-top: 30px;
}
@media (max-height: 600px) {
  #logo {
    margin-top: 30px;
  }
}

#btnLogin {
  margin: 0;
  font-family: "Open Sans";
  font-size: 14px;
  border-radius: 3px;
  text-decoration: none;
  box-sizing: border-box;
  min-width: 110px;
  height: 40px;
}

.card {
  margin-bottom: 50px;
}
.card .card-body {
  padding: 2rem;
}
.card .card-body.wfm-login-title {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 18px;
}
@media (max-width: 575.98px) {
  .card .card-body {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
.card .wfm-login-title {
  border-bottom: 1px solid #adb5bd;
}

.language .wfm-input-group label {
  font-size: 13px;
  text-transform: uppercase;
  padding-left: 0;
  font-weight: 400;
  color: #9aa4ab;
  display: block;
  position: static;
  margin-bottom: 0.25rem;
}

footer.copyRight-contactUs {
  color: #fff;
  text-align: center;
  width: 100%;
  display: block;
  padding: 0;
  margin: -50px auto 0;
  height: 50px;
}

.wfm-input-group .form-control {
  height: calc(2.5rem + 2px);
}

.text-danger {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.login-loading-content {
  padding: 20px;
  background-color: #E1EAF2;
  display: flex;
  flex-flow: column nowrap;
  max-height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.login-loading-content .page-header {
  flex: 0 0 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.vertical-center {
  min-height: 100%; /* Fallback for vh unit */
  min-height: 100vh; /* You might also want to use
   'height' property instead.

   Note that for percentage values of
   'height' or 'min-height' properties,
   the 'height' of the parent element
   should be specified explicitly.

   In this case the parent of '.vertical-center'
   is the <body> element */
  /* Make it a flex container */
  display: flex;
  /* Align the bootstrap's container vertically */
  align-items: center;
  /* In legacy web browsers such as Firefox 9
     we need to specify the width of the flex container */
  width: 100%;
  /* Also 'margin: 0 auto' doesn't have any effect on flex items in such web browsers
     hence the bootstrap's container won't be aligned to the center anymore.

     Therefore, we should use the following declarations to get it centered again */
  justify-content: center;
}

.input-group-password {
  justify-content: flex-end;
}

.input-txt-error {
  border-color: #dc3545;
}

div.input-group-text.is-invalid {
  height: 65%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
