// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 10px;
  width: 100%;
}

:host ::ng-deep #component-container .b-inputtext {
  min-width: 0;
}
:host ::ng-deep #component-container {
  margin-bottom: 10px;
  width: 100% !important;
}
:host ::ng-deep input-text {
  max-width: 100%;
}
:host ::ng-deep #component-container .data-grid-search-input {
  height: 30px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  border: 1px solid #d0d2d3 !important;
}
:host ::ng-deep #component-container .search-icon, :host ::ng-deep #component-container .clear-search-icon {
  top: 2px;
}
:host ::ng-deep svg {
  height: 16px;
}

.actions {
  color: #007cbe;
  font-size: 12px;
  padding-left: 1px;
}

.actions .select-all {
  margin: 0 19px 0 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
