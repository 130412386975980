// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
  outline: 0;
}

nav {
  color: #929394;
  width: 230px;
  background: white;
  overflow: visible;
  font-size: 14px;
  transition: transform 0.3s ease-out, width 0.3s ease-out;
  height: 100%;
  overflow-y: hidden;
  position: relative;
  z-index: 1020;
  box-shadow: 0 0 3px rgba(31, 50, 77, 0.2);
}

.menu-items {
  height: calc(100vh - 85px);
  overflow-y: hidden;
  overflow-x: hidden;
}
.menu-items::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
.menu-items::-webkit-scrollbar-button:start:decrement, .menu-items::-webkit-scrollbar-button:end:increment {
  height: 0;
  display: block;
  background-color: transparent;
}
.menu-items::-webkit-scrollbar-button:horizontal:start:decrement, .menu-items::-webkit-scrollbar-button:horizontal:end:increment {
  height: 0;
  display: block;
  background-color: transparent;
}
.menu-items::-webkit-scrollbar-track-piece {
  background-color: #293238;
}
.menu-items::-webkit-scrollbar-thumb:vertical, .menu-items::-webkit-scrollbar-thumb:horizontal {
  background-color: #bbb;
}
.menu-items:hover {
  overflow-y: auto;
}
.menu-items > ul {
  padding-bottom: 20px;
}

header {
  border-bottom: 2px solid #ebeff2;
  padding: 5px 5px 8px 5px;
  transition: padding ease-out 0.3s;
  height: 35px;
  overflow: hidden;
}
header a.toggle-menu-collapse {
  position: relative;
  transition: left 0.3s ease-out, right 0.3s ease-out;
  outline: none;
}
header a.toggle-menu-collapse .icon {
  font-size: 18px;
  line-height: 18px;
  color: #93a4aa;
}
header a.toggle-menu-collapse .icon:hover {
  color: #007cbe;
  cursor: pointer;
}

a.menu-item {
  display: flex;
  cursor: pointer;
  margin: 0;
  color: rgba(45, 74, 89, 0.75);
  padding-top: 0px;
  padding-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
  height: 60px;
  position: relative;
  font-size: 14px;
  justify-content: left;
  align-items: center;
  font-weight: 600;
}
a.menu-item .menu-item-icon {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  padding-top: 9px;
  padding-left: 11px;
  font-size: 20px;
  margin: 10px 0;
  color: rgb(0, 167, 234);
}
a.menu-item .menu-item-icon:hover {
  cursor: pointer;
}
a.menu-item .menu-item-title {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  height: 41px;
  width: 156px;
  text-overflow: ellipsis;
  overflow: hidden;
}
a.menu-item .menu-item-title:hover {
  cursor: pointer;
}
a.menu-item .menu-item-expand {
  animation-duration: 0.5s;
  fill: #929394;
  height: 9px;
  width: 12px;
  margin: 14px 0 0 0;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  position: absolute;
  top: 0;
}
a.menu-item .menu-item-expand.expanded {
  transition: all 0.3s ease-in-out 0s;
  transform: rotate(180deg);
}

nav > ul > li:hover .menu-item,
.menu-item:hover,
a.menu-item:not([href]):not([tabindex]):hover,
.menu-item.active-item {
  cursor: pointer;
  text-decoration: none;
}
nav > ul > li:hover .menu-item .menu-item-icon,
.menu-item:hover .menu-item-icon,
a.menu-item:not([href]):not([tabindex]):hover .menu-item-icon,
.menu-item.active-item .menu-item-icon {
  cursor: pointer;
  opacity: 1;
}
nav > ul > li:hover .menu-item .menu-item-expand,
.menu-item:hover .menu-item-expand,
a.menu-item:not([href]):not([tabindex]):hover .menu-item-expand,
.menu-item.active-item .menu-item-expand {
  cursor: pointer;
  fill: #FFF;
}
nav > ul > li:hover .menu-item .menu-item-expand:hover,
.menu-item:hover .menu-item-expand:hover,
a.menu-item:not([href]):not([tabindex]):hover .menu-item-expand:hover,
.menu-item.active-item .menu-item-expand:hover {
  cursor: pointer;
}

.sub-menu {
  padding: 0;
  background-color: #182127;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}
.sub-menu li:first-child {
  padding-top: 10px;
}
.sub-menu li:last-child {
  padding-bottom: 10px;
}
.sub-menu.expanded {
  max-height: 500px;
  transition: max-height 0.3s ease-in;
}

a:hover {
  background-color: #f2f5f7;
}

a.active-item {
  background-color: #f2f5f7;
  color: #0a2533;
}

a.active-item .menu-item-icon {
  background: #00a7ea;
  color: white;
}

a.sub-menu-item {
  cursor: pointer;
  display: block;
  margin: 0;
  color: #929394;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 207px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a.sub-menu-item:hover {
  cursor: pointer;
  color: white;
}
a.sub-menu-item.active-sub-item, a.sub-menu-item.active-sub-item:hover {
  color: #f2f5f7;
}

nav.menu-collapsed {
  transform: translate(0, 0);
  width: 64px;
  transition: transform 0.3s ease-out, width 0.3s ease-out;
}
nav.menu-collapsed .menu-items {
  overflow-y: hidden;
}
nav.menu-collapsed .sub-menu,
nav.menu-collapsed .menu-item-title,
nav.menu-collapsed .menu-item-expand {
  display: none;
}
nav.menu-collapsed:hover, nav.menu-collapsed.in-focus {
  width: 230px;
}
nav.menu-collapsed:hover .menu-items:hover, nav.menu-collapsed.in-focus .menu-items:hover {
  overflow-y: auto;
}
nav.menu-collapsed:hover .sub-menu,
nav.menu-collapsed:hover .menu-item-title,
nav.menu-collapsed:hover .menu-item-expand, nav.menu-collapsed.in-focus .sub-menu,
nav.menu-collapsed.in-focus .menu-item-title,
nav.menu-collapsed.in-focus .menu-item-expand {
  display: block;
}
nav.menu-collapsed:hover.route-triggered, nav.menu-collapsed.in-focus.route-triggered {
  width: 60px;
}
nav.menu-collapsed:hover.route-triggered .menu-items, nav.menu-collapsed.in-focus.route-triggered .menu-items {
  overflow-y: hidden;
}
nav.menu-collapsed:hover.route-triggered .sub-menu,
nav.menu-collapsed:hover.route-triggered .menu-item-title,
nav.menu-collapsed:hover.route-triggered .menu-item-expand, nav.menu-collapsed.in-focus.route-triggered .sub-menu,
nav.menu-collapsed.in-focus.route-triggered .menu-item-title,
nav.menu-collapsed.in-focus.route-triggered .menu-item-expand {
  display: none;
}

/**
 *  The following items need different styles
 *  if the language is right-to-left (rtl)
 */
::ng-deep body {
  /* a.menu-item */
  /* a.toggle-menu-collapse */
  /* a.menu-item .menu-item-icon */
  /* a.menu-item .menu-item-title */
  /* a.menu-item .menu-item-expand */
  /* a.sub-menu-item */
}
::ng-deep body app-side-nav a.menu-item {
  padding-right: 10px;
  padding-left: 10px;
}
::ng-deep body.rtl app-side-nav a.menu-item {
  padding-right: 20px;
  padding-left: 10px;
}
::ng-deep body app-side-nav a.toggle-menu-collapse {
  left: 196px;
}
::ng-deep body app-side-nav a.toggle-menu-collapse img {
  transform: rotate(0deg);
}
::ng-deep body.rtl app-side-nav a.toggle-menu-collapse {
  left: auto;
  right: 196px;
}
::ng-deep body.rtl app-side-nav a.toggle-menu-collapse .icon {
  transform: rotate(180deg);
}
::ng-deep body app-side-nav nav.menu-collapsed header a.toggle-menu-collapse {
  left: 15px;
  right: auto;
}
::ng-deep body app-side-nav nav.menu-collapsed header a.toggle-menu-collapse .icon {
  transform: rotate(180deg);
}
::ng-deep body.rtl app-side-nav nav.menu-collapsed header a.toggle-menu-collapse {
  left: auto;
  right: 15px;
}
::ng-deep body.rtl app-side-nav nav.menu-collapsed header a.toggle-menu-collapse .icon {
  transform: rotate(0deg);
}
::ng-deep body app-side-nav a.menu-item .menu-item-icon {
  float: left;
}
::ng-deep body.rtl app-side-nav a.menu-item .menu-item-icon {
  float: right;
}
::ng-deep body app-side-nav a.menu-item .menu-item-title {
  padding-right: 0px;
  padding-left: 10px;
  float: left;
}
::ng-deep body.rtl app-side-nav a.menu-item .menu-item-title {
  padding-right: 10px;
  padding-left: 0px;
  float: right;
}
::ng-deep body app-side-nav a.menu-item .menu-item-expand {
  left: 196px;
  right: auto;
}
::ng-deep body.rtl app-side-nav a.menu-item .menu-item-expand {
  left: auto;
  right: 196px;
}
::ng-deep body app-side-nav a.sub-menu-item {
  padding-right: 0px;
  padding-left: 50px;
}
::ng-deep body.rtl app-side-nav a.sub-menu-item {
  padding-right: 50px;
  padding-left: 0px;
}

.menu-items:focus {
  outline: none;
}

.menu-items > ul > li > a {
  border: 1px dotted transparent;
}

.keyboard-triggered .menu-items:focus > ul > li[aria-selected=true] > a {
  border: 1px dotted white;
}

.keyboard-triggered .menu-items:focus li li[aria-selected=true] span {
  outline: 1px dotted white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
