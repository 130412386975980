// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* modal dialogs */
.modal-header {
  font-family: "Open Sans", Arial, sans-serif;
  border-bottom: 1px solid #DAE2E8;
  border-radius: 0;
}

.modal-body {
  min-height: 90px;
  background-color: #e9f1f5;
  padding: 20px;
  margin-left: 1px;
}
.modal-body .modal-buttons {
  text-align: center;
}

.modal-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #404040;
  margin-left: 5px;
}

.modal-footer {
  padding: 12px 8px;
  border-top: solid 1px #d0d5d9;
}

::ng-deep .refresh-modal .btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

::ng-deep .wfm-modal {
  z-index: 1070;
}
::ng-deep .wfm-modal .modal-content {
  border-radius: 0;
  width: 540px;
  border: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-header {
  height: 80px;
  align-items: center;
  border-bottom: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-footer {
  height: 80px;
  border-top: solid 1px #d0d5d9;
}

::ng-deep .wfm-modal-backdrop {
  z-index: 1070 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
