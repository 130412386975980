// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-link.btn-anchor {
  outline: none !important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.modal-body {
  font-size: 14px;
}

/* native progressbar */
.b-p-progress {
  width: 370px;
  height: 15px;
  border: solid 1px #d0d5d9;
  display: inline-block;
  box-sizing: border-box;
}

.b-progress-bar {
  background-color: #237bc2;
  display: inline-block;
  height: 14px;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.b-p-label {
  color: #707070;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

.b-p-textlabel {
  color: #707070;
  font-size: 12px;
  margin-right: 5px;
}

.error-message-label {
  position: relative;
  padding-left: 5px;
  font: 400 14px "Open Sans Regular";
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
