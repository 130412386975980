// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .cell-span {
  background-color: white;
  border-bottom: 1px solid #c1bebe !important;
}

.table-list {
  border: 1px solid #c1bebe;
}

::ng-deep .groupedColumnHeader {
  font-size: 11px;
  font-weight: bold;
  color: #2E2E2E;
  text-transform: uppercase;
}

::ng-deep .ag-header-cell .ag-header-cell-label .ag-header-cell-text {
  margin-left: 5px !important;
}

.muSelectorDropDown {
  display: inline-block;
  width: 270px;
  text-align: left;
}

:host ::ng-deep .ag-header-cell {
  padding-right: 0px;
}

wfm-task-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

::ng-deep wfm-esp-base-header {
  width: 100%;
}

.embedded-info {
  margin-left: 14px;
}

.toolbar-container {
  justify-content: space-between;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
