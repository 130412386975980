// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wrapper {
  font-size: 11px;
}

::ng-deep ngb-datepicker {
  width: 100%;
}

::ng-deep .ngb-dp-month {
  width: 100%;
}

::ng-deep .ngb-dp-day, ::ng-deep .ngb-dp-week-number, ::ng-deep .ngb-dp-weekday {
  width: 100% !important;
}

::ng-deep [ngbDatepickerDayView] {
  width: 100% !important;
}

::ng-deep .ngb-dp-day:focus {
  outline: none;
}

::ng-deep .ngb-dp-day .btn-light:focus {
  outline: none;
}

::ng-deep .ngb-dp-day .btn-light {
  width: 100%;
  display: inline-block;
  text-align: center;
  height: 100%;
  padding-top: 4px;
}

::ng-deep .ngb-dp-day .btn-light.range {
  background: #007cbe !important;
  color: white !important;
}

::ng-deep .ngb-dp-day .btn-light.faded {
  background: #D9EBF5 !important;
}

::ng-deep .ngb-dp-day .btn-light.hover {
  background: #e9edf0;
}

::ng-deep .input-group .ngb-dp-weekday {
  color: #5B7C8C;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: middle;
  font-style: normal;
}

::ng-deep .ngb-dp-day.disabled .btn-light {
  color: grey;
}

::ng-deep .ngb-dp-day .btn-light:hover {
  color: #2e2e2e;
  border-width: 0px;
}

::ng-deep .ngb-dp-arrow-btn {
  position: relative;
  min-width: 0;
  min-height: 0;
  height: 30px;
}

.cal svg {
  height: 20px;
  fill: #93a4aa;
  position: relative;
}

.input-group {
  border: 1px solid #d0d2d3;
  height: 35px;
  background: white;
}

.input-group button:hover {
  background-color: #007cbe;
  border-left: 1px solid;
  border-color: #007cbe;
}

.input-group button:hover svg {
  fill: white;
}

.input-group button {
  border: none;
  height: 33px;
  width: 40px;
  min-width: 0;
  padding: 0;
  border-left: 1px solid #d0d2d3;
  border-radius: 0;
}

.input-group input {
  border: none;
  height: 33px;
}

input.datepicker {
  position: absolute;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

input.form-control {
  background: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
