// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  font-family: "Open Sans", Arial, sans-serif;
  border-bottom: 1px solid #DAE2E8;
  border-radius: 0;
}

.modal-body {
  font-size: 14px;
  min-height: 90px;
  min-width: 500px;
  background-color: #e9f1f5;
  padding: 20px;
  margin-left: 0px;
  line-height: 1.5;
}

textarea {
  resize: none;
  font-size: 12px;
  margin-top: 5px;
}

.modal-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #404040;
  margin-left: 5px;
}

.modal-footer {
  padding: 12px 8px;
  border-top: solid 1px #d0d5d9;
}

::ng-deep .wfm-modal .modal-content {
  border-radius: 0;
  border: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-header {
  height: 80px;
  align-items: center;
}
::ng-deep .wfm-modal .modal-header.warn {
  border-bottom: solid 1px #FC9537;
}
::ng-deep .wfm-modal .modal-header.error {
  border-bottom: solid 1px #fa3f40;
}
::ng-deep .wfm-modal .modal-footer {
  height: 80px;
  border-top: solid 1px #d0d5d9;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
