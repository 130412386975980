// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{
  display: flex;
}
.validation-navigator{
  background: #fff1f1;
  border: 1px solid #cc9b9b;
  color: #6b7984;
  display:flex;
  height: 40px;
  padding:4px 14px;
  border-radius: 5px;
  align-items: center;
  gap:8px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  transition: color 0.05s ease-in-out, background-color 0.05s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0px 1px 0px 0px #cc9b9b !important;

}
.validation-navigator:hover:active{
  border: 1px solid #D23C40;
  background: #f8dddd;

}
.validation-navigator:hover{
  border: 1px solid #D23C40;
  background: #FFECEC;

}
.icon-system_error{
  color: #d24444;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
