// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body{
  font-size: 14px;
}
.embedded-warning{
  margin: 0px 0 -12px 0;
  max-width: 475px;
}
.embedded-warning.patience{
  margin-top: 12px;
}
.input-label.patience{
  margin-top: 20px;
  margin-bottom: -5px;
}
input.form-control.form-control-inline.patience{
  margin-right: 0px;
}
.inline-input {
  padding-left: 5px;
}

::ng-deep .breeze .popover-tooltip{
  max-width: 260px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
