// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1200px) {
  .modal-body.convert-modal-body {
    min-width: 500px;
  }
}
.primary-text {
  font-size: 15px;
  font-weight: bold;
  color: #464646;
}

.row.separator {
  margin: 18px 0;
  height: 1px;
  background: #d9d9d9;
}

.group-title {
  margin-bottom: 5px;
}

.input-label {
  margin-left: 2px;
  margin-top: 8px;
}

.warning-message i {
  color: #ffa638;
  font-size: 18px;
  vertical-align: text-bottom;
}

.warning-message {
  font-size: 14px;
  margin-top: 14px;
}

.popover {
  max-width: none;
}

.popover .button-response {
  width: 325px;
}

wfm-plan-loading-overlay {
  top: 0;
  bottom: 0;
  position: absolute;
  left: -1px;
  right: 0;
  background: rgba(0, 0, 0, 0.0784313725);
  z-index: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
