// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fte-setting {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #cccccc;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
}

.hours-per-day {
  padding-top: 18px;
  padding-bottom: 6px;
}

.hours-per-week {
  padding-top: 18px;
  padding-bottom: 6px;
}
.time-picker-container{
  display: flex;
  align-items: center;
}
.side-label{
  margin-left:16px;
}

form {
  margin-top: 24px;
}
form input.p-inputnumber{
  width:93px;
}
form table td{
  vertical-align: top;
}
table td .month-label{
  line-height:32px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
