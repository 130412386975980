// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* loading animation */
.spinner,
.spinner:before,
.spinner:after {
  width: 4px;
  height: 20px;
  background-color: #237bc2;
  border-radius: 0px;
}

.spinner {
  display: inline-block;
  position: relative;
}

.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  display: block;
  top: 0px;
}

.spinner:before {
  left: -6px;
}

.spinner:after {
  left: 6px;
}

@keyframes bounce-middle {
  0% {
    height: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
  }
  50% {
    height: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #237bc2;
  }
  100% {
    height: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
  }
}

.spinner-bounce-middle {
  animation: bounce-middle 0.6s ease 0.1s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner-bounce-middle:before,
.spinner-bounce-middle:after {
  top: 50%;
  transform: translateY(-10px) translateZ(0);
}

.spinner-bounce-middle:before {
  animation: bounce-middle 0.6s ease 0s infinite;
}

.spinner-bounce-middle:after {
  animation: bounce-middle 0.6s ease 0.2s infinite;
}

`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
