// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-wrapper:hover {
  color: #4f95ce;
}

.icon-wrapper {
  display: inline-flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 20px;
  color: #93a4aa;
  margin-left: 8px;
  align-content: center;
  justify-content: center;
  position: relative;
  top: 3px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
