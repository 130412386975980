// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body.save-as-modal-body {
  min-width: 500px;
}

wfm-plan-loading-overlay {
  top: 0;
  bottom: 0;
  position: absolute;
  left: -1px;
  right: 0;
  background: rgba(0, 0, 0, 0.0784313725);
  z-index: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
