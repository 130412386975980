// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* buttons */
/* polyphil for outlines */
.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: none;
}

/* primary button */
.btn {
  font-family: "Open Sans";
  font-size: 14px;
  border-radius: 3px;
  text-decoration: none;
  box-sizing: border-box;
  min-width: 110px;
  height: 40px;
}
.btn:focus {
  outline: 0 !important;
  box-shadow: none;
}

.btn.btn-primary {
  color: #fff;
  background-color: #007cbe;
  border-color: #007cbe;
  box-shadow: 0px 2px 0px 0px #1c639c;
}

.btn.btn-primary:hover, .btn-primary:focus:hover:not(:active) {
  background-color: #298fd8;
  border-color: #298fd8;
  box-shadow: 0px 2px 0px 0px #237cbb;
}

.btn.btn-primary:focus {
  background-color: #007cbe;
  border-color: #007cbe;
  box-shadow: 0px 2px 0px 0px #1c639c;
}

.btn.btn-primary:active {
  background-color: #006398;
  border-color: #006398;
  box-shadow: none; /* 0px 2px 0px 0px #006398;*/
}

/* secondary button */
.btn.btn-secondary {
  color: #6b7984;
  background-color: #fff;
  border-color: #dae2e8;
  box-shadow: 0px 2px 0px 0px #dae2e8;
}

.btn.btn-secondary:hover, .btn-secondary:hover:focus:not(:active) {
  background-color: #fff;
  border-color: #6b8ea8;
  box-shadow: 0px 2px 0px 0px #6b8ea8;
}

.btn.btn-secondary:focus {
  background-color: #fff;
  border-color: #dae2e8;
  box-shadow: 0px 2px 0px 0px #dae2e8;
}

.btn.btn-secondary:active {
  background-color: #ecf2f6;
  border-color: #afc1cf;
  box-shadow: none;
}

/* disabled buttons */
.btn.disabled,
.btn.disabled:hover,
.btn[disabled]:hover,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled:active,
.btn[disabled]:active,
.btn.disabled.active,
.btn[disabled].active {
  background-color: #eeeeee !important;
  border-color: #dde4e9 !important;
  color: #697782 !important;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  box-shadow: 0px 2px 0px 0px #dae2e8 !important;
  cursor: default;
}

/* red button */
.btn.btn-red {
  color: #fff;
  background-color: #D44646;
  border-color: #AA3232;
  box-shadow: 0px 2px 0px 0px #c84f4f;
}

.btn.btn-red:hover, .btn-red:hover:focus:not(:active) {
  background-color: #f96c6c;
  border-color: #fa4549;
  box-shadow: 0px 2px 0px 0px #c95555;
}

.btn.btn-red:focus {
  background-color: #D44646;
  border-color: #AA3232;
  box-shadow: 0px 2px 0px 0px #c84f4f;
}

.btn.btn-red:active {
  background-color: #c11a1a;
  border-color: #c11a1a;
  box-shadow: none;
}

/* buttons sizes */
.btn.btn-lg {
  padding: 8px 25px 6px;
  line-height: 24px;
  margin: 0 10px;
}

.btn.btn-sm {
  padding: 8px 25px 6px;
  font-size: 14px;
  line-height: 14px;
  margin: 0 6px;
}

.btn.btn-xs {
  padding: 4px 15px 2px;
  font-size: 11px;
  line-height: 10px;
  margin: 0 4px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
