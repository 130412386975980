// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-list.table-list {
  border: 1px solid #c1bebe;
}

::ng-deep .plan-details-day plan-detail-clickable-header {
  display: none;
}

.plan-detail-container {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.plan-detail-container .slider {
  flex: 1 1 auto;
}

.plan-detail-container ag-grid-angular {
  position: absolute;
  height: 100%;
  width: 100%;
}

plan-detail-chart {
  height: calc(100vh - 230px);
  display: block;
}

.contactTypeDropDown {
  display: inline-block;
  width: 270px;
  text-align: left;
}

.row.slider {
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 1px solid #c1bebe;
}

.slide {
  width: 100%;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 0;
  /* transform: translateX(-100%); */
  transition: all 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  flex-basis: auto;
}

.slider.alternate .slide {
  transform: translateX(-100%);
}

.icon {
  font-size: 20px;
  vertical-align: top;
  margin-right: 0.25rem;
}

:host ::ng-deep button.btn-lg.no-right-margin {
  margin-right: 0;
}
:host ::ng-deep .breadcrumb {
  background-color: transparent;
  margin-bottom: 5px;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  font-size: 12px;
}
:host ::ng-deep .breadcrumb > li {
  display: inline-block;
}
:host ::ng-deep .breadcrumb > li a {
  color: #0094D1;
}
:host ::ng-deep #btn-action button {
  min-width: 40px;
  padding: 0;
}
:host ::ng-deep #dropdownSettings {
  min-width: 0;
}
:host ::ng-deep #dropdownSettings:after {
  display: none;
}

.search-box {
  padding: 0;
}

.btn-rect {
  min-width: 0;
  width: 40px;
}

:host ::ng-deep .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #dae2e8;
  box-shadow: 0 0 0 0 transparent;
}

:host ::ng-deep .dropdown-menu {
  border-radius: 0;
  padding: 0;
}
:host ::ng-deep .dropdown-menu .dropdown-item {
  color: #888;
  padding: 0.75rem 0.75rem;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
}
:host ::ng-deep .dropdown-menu .dropdown-item svg {
  fill: #888;
  margin-right: 0.25rem;
}
:host ::ng-deep .dropdown-menu .dropdown-item[disabled] {
  opacity: 0.5;
}
:host ::ng-deep .dropdown-menu .dropdown-item:not([disabled]):hover {
  background-color: #007db8;
  color: #FFF;
}
:host ::ng-deep .dropdown-menu .dropdown-item:not([disabled]):hover svg {
  fill: #FFF;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
