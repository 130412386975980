// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-list.table-list {
  border: 1px solid #c1bebe;
}

.plan-detail-container {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.plan-detail-container .slider {
  flex: 1 1 auto;
}

.plan-detail-container ag-grid-angular {
  position: absolute;
  height: 100%;
  width: 100%;
}

plan-multi-chart {
  height: 100%;
  display: block;
}

.contactTypeDropDown {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-right: 5px;
}

.ctmu-container {
  display: flex;
  align-items: center;
}

.ctmu-container wfm-select {
  flex: 1 1;
  max-width: 400px;
  min-width: 100px;
}

.row.slider {
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 1px solid #c1bebe;
}

.slide {
  width: 100%;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 0;
  /* transform: translateX(-100%); */
  transition: all 0.8s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  flex-basis: auto;
}

.slider.alternate .slide {
  transform: translateX(-100%);
}

.icon {
  font-size: 20px;
  vertical-align: top;
}

:host ::ng-deep button.btn-lg.no-right-margin {
  margin-right: 0;
}
:host ::ng-deep .breadcrumb {
  background-color: transparent;
  margin-bottom: 5px;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  font-size: 12px;
}
:host ::ng-deep .breadcrumb > li {
  display: inline-block;
}
:host ::ng-deep .breadcrumb > li a {
  color: #0094D1;
}
:host ::ng-deep #btn-action button {
  min-width: 40px;
  padding: 0;
}
:host ::ng-deep #dropdownSettings {
  min-width: 0;
}
:host ::ng-deep #dropdownSettings:after {
  display: none;
}

.search-box {
  padding: 0;
}

.btn-rect {
  min-width: 0;
  width: 40px;
  padding: 0px;
}

.btn-group:first-of-type {
  margin-left: 0;
  margin-right: 8px;
}

.btn-group {
  margin-left: 10px;
}

:host ::ng-deep .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fff;
  border-color: #dae2e8;
  box-shadow: 0 0 0 0 transparent;
}

wfm-task-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

::ng-deep .plan-details-day plan-detail-clickable-header {
  display: none;
}

:host ::ng-deep .dropdown-menu {
  border-radius: 0;
  padding: 0;
}
:host ::ng-deep .dropdown-menu .dropdown-item {
  color: #888;
  padding: 0.75rem 0.75rem;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
}
:host ::ng-deep .dropdown-menu .dropdown-item svg {
  fill: #888;
  margin-right: 0.25rem;
}
:host ::ng-deep .dropdown-menu .dropdown-item[disabled] {
  opacity: 0.5;
}
:host ::ng-deep .dropdown-menu .dropdown-item:not([disabled]):hover {
  background-color: #007db8;
  color: #FFF;
}
:host ::ng-deep .dropdown-menu .dropdown-item:not([disabled]):hover svg {
  fill: #FFF;
}

.loading-indicator {
  /*  background: #ffffffd4;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;*/
}

:host ::ng-deep .ag-header-viewport, :host ::ng-deep .ag-body-viewport, :host ::ng-deep .ag-body-horizontal-scroll {
  opacity: 1;
  transition: opacity cubic-bezier(0.65, 0.05, 0.36, 1) 150ms;
}
:host ::ng-deep .data-rendering .ag-header-viewport,
:host ::ng-deep .data-rendering .ag-body-horizontal-scroll,
:host ::ng-deep .data-rendering .ag-body-viewport {
  opacity: 0;
}
:host ::ng-deep .data-rendered .ag-header-viewport,
:host ::ng-deep .data-rendered .ag-body-horizontal-scroll,
:host ::ng-deep .data-rendered .ag-body-viewport {
  opacity: 1;
}

/*:host ::ng-deep .multi-cell-edit{
  .ag-cell-range-bottom{
    border-bottom: 2px solid blue !important;
  }
  .ag-cell-range-selected-1{
    border: 1px solid blue;
  }
}*/
.toolbar-container {
  flex-wrap: nowrap;
}

.toolbar-items-left {
  flex-wrap: nowrap;
  flex: 0 1 auto;
}

.toolbar-items-center {
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.toolbar-items-right {
  flex: 0 0 auto;
}

.btn-discard {
  margin-left: 8px;
}

.message-undo {
  overflow-wrap: normal;
  max-width: 200pt;
}

.toolbar-items-left p-multiSelect {
  width: 260px;
}

:host ::ng-deep .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group {
  border-color: transparent;
}

:host ::ng-deep .rollup-collapsed .ag-floating-top {
  max-height: 36px;
  min-height: 0 !important;
}

:host ::ng-deep .toolbar-container .validation-navigator {
  height: 35px;
}

.toolbar-container .btn-rect {
  width: 35px;
  height: 35px;
}

wfm-esp-no-rows-overlay {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
