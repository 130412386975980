// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-wrapper button {
  height: 22px;
  width: 22px;
  padding: 0;
  border: 1px solid #c1bebe;
  margin-right: -96px;
  font-size: 10px;
  border-radius: 3px;
  min-width: 0;
}

::ng-deep .popover.reset-popup .button-response button {
  margin-right: 0;
  margin-left: 10px;
}

::ng-deep .popover.reset-popup .message-text p {
  color: black;
}

::ng-deep .popover.reset-popup {
  max-width: 300px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
