// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
  color: #d82132;
}

.show-error {
  display: block;
}

.invalid-input {
  border: solid 1px #d82132;
}

.valid-input {
  border: solid 1px rgb(96, 189, 96);
}

.input-label {
  color: #8c95a5;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

input {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  color: #333;
}
input::-ms-clear {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
