// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .modal-content {
  border-radius: 0;
}

:host ::ng-deep .ui-toast.ui-toast-bottom-right {
  right: 55px;
}

::ng-deep .ag-header-cell {
  color: #98A2B3;
  font-weight: bold;
}

:host ::ng-deep .ui-toast .ui-toast-message-text-content {
  padding: 0.5em;
}
:host ::ng-deep .ui-toast .ui-toast-message-text-content .ui-toast-summary {
  display: none;
}

::ng-deep .imported-forecasts-list .ag-overlay-loading-wrapper {
  background: white;
}

:host ::ng-deep .ui-toast-message-success {
  width: 342px;
  background: #23762D;
  color: white;
}
:host ::ng-deep .ui-toast-message-success .pi {
  font-family: "icomoon" !important;
}
:host ::ng-deep .ui-toast-message-success .ui-toast-icon {
  top: 50%;
  margin-top: -0.8em;
}
:host ::ng-deep .ui-toast-message-success .ui-toast-icon:before {
  content: "\\e926";
  font-size: 1.25rem;
}
:host ::ng-deep .ui-toast-message-success .ui-toast-message-content {
  font-size: 14px;
}
:host ::ng-deep .ui-toast-message-success .ui-toast-close-icon {
  top: 50%;
  margin-top: -0.8em;
}
:host ::ng-deep .ui-toast-message-success .ui-toast-close-icon:before {
  content: "\\e936";
  font-size: 1rem;
  vertical-align: middle;
  color: white;
  margin-right: 5px;
}
:host ::ng-deep .ui-toast-message-success .ui-toast-close-icon:hover {
  color: white;
  opacity: 1;
  text-decoration: none;
}
:host ::ng-deep .ui-toast-message-success .ui-toast-close-icon:focus {
  color: white;
  opacity: 1;
  text-decoration: none;
}

:host ::ng-deep .ui-toast-message-error {
  width: 342px;
  background: #c43535;
  color: white;
}
:host ::ng-deep .ui-toast-message-error .pi {
  font-family: "icomoon" !important;
}
:host ::ng-deep .ui-toast-message-error .ui-toast-icon {
  top: 50%;
  margin-top: -0.8em;
}
:host ::ng-deep .ui-toast-message-error .ui-toast-icon:before {
  content: "\\e95c";
  font-size: 1.25rem;
}
:host ::ng-deep .ui-toast-message-error .ui-toast-message-content {
  font-size: 14px;
}
:host ::ng-deep .ui-toast-message-error .ui-toast-close-icon {
  top: 50%;
  margin-top: -0.8em;
}
:host ::ng-deep .ui-toast-message-error .ui-toast-close-icon:before {
  content: "\\e936";
  font-size: 1rem;
  vertical-align: middle;
  color: white;
  margin-right: 5px;
}
:host ::ng-deep .ui-toast-message-error .ui-toast-close-icon:hover {
  color: white;
  opacity: 1;
  text-decoration: none;
}
:host ::ng-deep .ui-toast-message-error .ui-toast-close-icon:focus {
  color: white;
  opacity: 1;
  text-decoration: none;
}

.manage-forecasts-content {
  padding: 20px;
  background-color: #E1EAF2;
  display: flex;
  flex-flow: column nowrap;
  max-height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.manage-forecasts-content .page-header {
  flex: 0 0 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.manage-forecasts-content .page-header ::ng-deep .btn.btn-lg {
  margin: 0;
}
.manage-forecasts-content .search-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 76px;
  border-top: 1px solid #c1bebe;
  border-left: 1px solid #c1bebe;
  border-right: 1px solid #c1bebe;
}
.manage-forecasts-content .search-box ::ng-deep div.form-group {
  margin-bottom: 0;
}
.manage-forecasts-content .search-box ::ng-deep #component-container .data-grid-search-input {
  background-color: transparent;
}
.manage-forecasts-content .search-box ::ng-deep #component-container .data-grid-search-input::placeholder {
  color: #A8B3BF;
}
.manage-forecasts-content .imported-forecasts-list {
  flex: 1 1 100%;
  border-bottom: 1px solid #c1bebe;
  border-left: 1px solid #c1bebe;
  border-right: 1px solid #c1bebe;
}
.manage-forecasts-content ::ng-deep .ag-header {
  border: 1px solid #DAE2E8;
  color: #98A2B3;
  font-weight: bold;
}
.manage-forecasts-content ::ng-deep .ag-header .ag-header-viewport:after {
  content: "";
  position: absolute;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 1px 4px #BEBEBE;
  height: 1px;
}
.manage-forecasts-content ::ng-deep .ag-row {
  color: #404040;
}
.manage-forecasts-content ::ng-deep .ag-row.row-disabled {
  color: #909090;
}
.manage-forecasts-content ::ng-deep .ag-row-selected {
  border-bottom-color: #d6dde1;
}
.manage-forecasts-content ::ng-deep .ag-cell-focus {
  border: 1px solid transparent !important;
}
.manage-forecasts-content ::ng-deep .row-disabled {
  color: #909090;
}
.manage-forecasts-content ::ng-deep .row-disabled.ag-row-hover {
  background-color: white;
}
.manage-forecasts-content ::ng-deep .row-view-only {
  color: #909090;
}
.manage-forecasts-content ::ng-deep .row-view-only .cell-view-only-name {
  color: #404040;
}
.manage-forecasts-content ::ng-deep .checkbox-disabled .ag-selection-checkbox {
  pointer-events: none;
}
.manage-forecasts-content ::ng-deep .checkbox-disabled .ag-selection-checkbox.ag-hidden {
  display: flex !important;
}

/* loading animation */
.spinner,
.spinner:before,
.spinner:after {
  width: 4px;
  height: 20px;
  background-color: #237bc2;
  border-radius: 0px;
}

.spinner {
  display: inline-block;
  position: relative;
}

.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  display: block;
  top: 0px;
}

.spinner:before {
  left: -6px;
}

.spinner:after {
  left: 6px;
}
@keyframes bounce-middle {
  0% {
    height: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
  }
  50% {
    height: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #237bc2;
  }
  100% {
    height: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
  }
}
.spinner-bounce-middle {
  animation: bounce-middle 0.6s ease 0.1s infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner-bounce-middle:before,
.spinner-bounce-middle:after {
  top: 50%;
  transform: translateY(-10px) translateZ(0);
}

.spinner-bounce-middle:before {
  animation: bounce-middle 0.6s ease 0s infinite;
}

.spinner-bounce-middle:after {
  animation: bounce-middle 0.6s ease 0.2s infinite;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
