// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{
  width:100%;
  height:100%;
}
label{
  font-size: 13px;
  margin-left: 14px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  padding-right: 20px;
  margin-bottom: 0;
}
.item-separator{
  border-bottom: 1px solid #d9d9d9;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
