// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-content: center;
}

.header-wrapper {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: #2E2E2E;
}

button {
  height: 22px;
  width: 22px;
  padding: 6px;
  border: 0px;
  margin-right: -6px;
  font-size: 14px;
  min-width: 0;
  box-shadow: none;
  color: #6b7984;
}
button.btn.disabled, button.btn.disabled:hover, button.btn[disabled]:hover {
  background-color: #fff;
  box-shadow: none;
}

::ng-deep .popover.reset-popup .button-response button {
  margin-right: 0;
  margin-left: 10px;
}

::ng-deep .popover.reset-popup .message-text p {
  color: black;
  white-space: pre-wrap;
}

::ng-deep .popover.reset-popup {
  max-width: 300px;
}

.icon-file-file-copy {
  height: 22px;
  width: 22px;
  padding: 2px 3px;
  min-width: 0;
  margin-top: 5px;
  margin-left: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
