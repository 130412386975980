// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-ag-header {
  display: flex;
  flex-flow: row nowrap;
}
.custom-ag-header .customOneHeaderLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: bold;
  color: #2E2E2E;
}
.custom-ag-header.sort-enabled .customHeaderLabel {
  cursor: pointer;
}
.custom-ag-header .customSortDownLabel.active {
  display: block;
}
.custom-ag-header .customSortDownLabel.inactive {
  display: none;
}
.custom-ag-header .customSortUpLabel.active {
  display: block;
}
.custom-ag-header .customSortUpLabel.inactive {
  display: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
