// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* modal dialogs */
.modal-header {
  font-family: "Open Sans", Arial, sans-serif;
  border-bottom: 1px solid #DAE2E8;
  border-radius: 0;
}

.modal-body {
  min-height: 90px;
  background-color: #e9f1f5;
  padding: 20px;
  margin-left: 1px;
}

.modal-header .close {
  margin-top: 0px;
  opacity: 0.8;
  color: #afc1cf;
  padding: 0rem;
  margin: 0rem 0rem 0rem auto;
}

.modal-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #404040;
  margin-left: 5px;
}

.modal-header .close:hover, .modal-header .close:focus {
  opacity: 1;
  color: #afc1cf;
}

.modal-footer {
  padding: 12px 8px;
  border-top: solid 1px #d0d5d9;
}

/* error message */
.b-error .modal-header {
  border-bottom: solid 1px #fa3f40;
}

.b-error .ib-alert {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

/* warning message */
.b-warning .modal-header {
  border-bottom: solid 1px #f15d22;
}

.b-warning .ib-warning {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

/* info message */
.b-info .modal-header {
  border-bottom: solid 1px #43acd9;
}

.b-info .ib-info {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

.icon-Close {
  font-family: "icomoon" !important;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #93A4AA;
}

::ng-deep .wfm-modal .modal-content create-plan {
  border-radius: 0;
  min-height: 521px;
  width: 540px;
  border: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-header {
  height: 80px;
  align-items: center;
  border-bottom: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-footer {
  height: 80px;
  border-top: solid 1px #d0d5d9;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
