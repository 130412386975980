// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col, .row {
  height: 100%;
}

checkbox-list {
  flex: 1;
  overflow: hidden;
}

.chart-container {
  height: 100%;
  display: block;
  padding-top: 0px;
  background: white;
}

.sidebar-visible .col-chart {
  right: 280px;
}

.sidebar-float .col-params {
  position: fixed;
  z-index: 1;
}

.col-chart {
  padding-right: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0px;
  top: 0;
  bottom: 0;
  width: auto;
}

.chart-view-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.view-wrapper {
  overflow: hidden;
  flex-wrap: nowrap;
}

.btnRollUp {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0;
  margin: 0;
  height: 30px;
  color: #0094D1;
}

.btnParams:hover {
  background: #e6e6e6;
}

.btnParams {
  position: absolute;
  top: 8px;
  right: 46px;
  background: transparent;
  border: none;
  width: 26px;
  padding-left: 4px;
  /*position: absolute;
  bottom: 19px;
  right: 17px;
  background: transparent;
  border: none;
  width: 26px;
  padding-left: 4px;*/
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
