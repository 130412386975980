// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sso-login-error-container {
    min-height: 100%;
    background-attachment: fixed;
    background-image: url("/assets/images/background-pattern.svg"), linear-gradient(rgb(0, 158, 218), rgb(0, 124, 190));
    height: 100%;
    overflow: auto;
  }
  .sso-login-error-container .container {
    padding-bottom: 50px;
  }

  #nice-img {
    width: 100px;
    height: 70px;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  #status-failed{
    padding-top: 23px;
    padding-bottom: 15px;
  }

  #status-failed-img {
    width: 64px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  #sso-login-error-title {
    padding-bottom: 10px;
  }

  #sso-login-error-title-p {
    text-align: center;
    min-height: 48px;
    height: auto;
    max-height: 72px;
    margin: 0;
    display : flex;
    align-items : center;
    justify-content : center;
    font-size : 18px;
    font-weight: 600;
  }

  #sso-login-error-message {
    padding-bottom: 10px;
  }

  #sso-login-error-message-p {
    text-align: center;
    min-height: 38px;
    height: auto;
    max-height: 57px;
    margin: 0;
    display : flex;
    align-items : center;
    justify-content : center;
    font-size : 13px;
    font-weight : normal;
  }

  #btnLoginToESP{
    margin:0;
    font-family: "Open Sans";
    font-size: 14px;
    border-radius: 3px;
    text-decoration: none;
    box-sizing: border-box;
    min-width: 110px;
    height: 40px;
  }

  .card {
    margin-top: 24px;
    min-height: 284px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  footer.copyRight-contactUs {
    color: #fff;
    text-align: center;
    width: 100%;
    display: block;
    padding: 0;
    margin: -50px auto 0;
    height: 50px;
  }

  .vertical-center {
    min-height: 100%;  /* Fallback for vh unit */
    min-height: 100vh; /* You might also want to use
                          'height' property instead.

                          Note that for percentage values of
                          'height' or 'min-height' properties,
                          the 'height' of the parent element
                          should be specified explicitly.

                          In this case the parent of '.vertical-center'
                          is the <body> element */

    /* Make it a flex container */
    display: flex;

    /* Align the bootstrap's container vertically */
    align-items : center;

    /* In legacy web browsers such as Firefox 9
       we need to specify the width of the flex container */
    width: 100%;

    /* Also 'margin: 0 auto' doesn't have any effect on flex items in such web browsers
       hence the bootstrap's container won't be aligned to the center anymore.

       Therefore, we should use the following declarations to get it centered again */
    justify-content : center;
  }
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
