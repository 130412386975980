// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-row {
  padding-top: 10px;
}

.last-row {
  padding-top: 20px;
}

.read-only-row {
  padding-top: 15px;
}

::ng-deep .wfm-modal.api-keys {
  z-index: 1062;
}

::ng-deep .api-keys-dialog.modal-dialog .modal-content {
  width: auto;
}

.api-credentials .modal-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

breeze-button#generate-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.modal-body {
  margin-left: 0;
  width: 470px;
}

.embedded-warning {
  font-size: 14px;
  margin: 0 0 -6px 0;
}

::ng-deep .popover.deactivate-popup .button-response button {
  margin-right: 0;
  margin-left: 10px;
}

::ng-deep .popover.deactivate-popup .message-text p {
  color: black;
}

::ng-deep .popover.deactivate-popup {
  max-width: 300px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
