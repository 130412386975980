// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-progress-container {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.b-p-progress {
  max-width: 200px;
  border: 1px solid #DAE2E8;
  background: white;
}

.b-p-label {
  color: #404040;
  font: Bold 16px/22px Open Sans;
  width: 45px;
}

.b-p-textlabel {
  font: Regular 13px/18px Open Sans;
  color: #404040;
  width: 70px;
  text-align: right;
}

.b-progress-bar {
  transition: width 0.2s ease-in;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
