// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{
  display:flex;
}
.custom-header{
  height:36px;
  display:flex;
  align-items: center;
  flex:1 1 auto;
  line-height:27px;
  overflow:hidden;
}
.rollup-header{
  margin-left:11px
}
.ag-group-expanded,.ag-group-contracted{
  display: inline-block;
  margin-left:11px;
}
.nhtp-link{
  margin-left:16px;
  flex-shrink: 0;
}
.header-title{
  overflow:hidden;
  display:flex;
}
.group-name{
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
