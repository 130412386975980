// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=number] {
  -moz-appearance: textfield;
}

input.numeric-editor:focus {
  box-shadow: none;
  /*border: 1px solid #226a97;*/
  outline: none;
}

input.numeric-editor {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  padding: 0px 7px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.popover {
  font-family: "Open Sans Regular";
  background-color: black;
  padding: 20px 20px 22px 20px;
}
.popover .popover-header {
  background-color: transparent;
  color: #fff;
  border-bottom: none;
  padding: 12px 14px 10px;
}
.popover .popover-body {
  padding: 0;
}
.popover p {
  color: white;
}
.popover .button-response {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.popover .button-response button {
  min-width: 70px;
  height: 30px;
  line-height: 50%;
  margin-left: 0;
}
.popover.bs-popover-top .arrow::after {
  border-top-color: black;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
