// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label{
  margin-bottom: 2px;
}
p{
  margin-bottom: 8px;
}
.modal-body{
  //width:500px;
}
ag-grid-angular{
  height:274px;
  width:500px;
  border:0px solid #c1bebe;
  margin-top: 4px;
}
:host ::ng-deep .ag-body-viewport{
  border-left:1px solid #dae2e8;
  border-right:1px solid #dae2e8;
  border-bottom:1px solid #dae2e8;
}
:host ::ng-deep .ag-row-first:not(.ag-row-hover){
  background: #f5f5f5;
}
:host ::ng-deep .ag-row-first{
  border-bottom: 2px solid #d9dcde;
  font-weight: 600;
}
:host ::ng-deep .ag-center-cols-container :not(.ag-row-first) [col-id="displayName"]{
  padding-left: 24px;
}
.close:focus-visible,.icon-Close:focus-visible{
  outline:none;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
