// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-list {
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  height: 100%;
}

.checkbox-list .checkbox-list-item.nested {
  padding-left: 30px;
}

.checkbox-list .checkbox-list-item {
  padding-top: 8px;
  padding-bottom: 3px;
  margin-bottom: 0;
  border-bottom: solid 1px #dae2e8;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-right: 0px;
  height: 41px;
  position: relative;
  padding-right: 43px;
}

.checkbox-list .checkbox-list-item:hover {
  background: #f5f8fa;
}

.checkbox-list .checkbox label {
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline;
}

p-inputswitch {
  position: absolute;
  left: 275px;
  margin-top: 2px;
}

.hide {
  display: none;
}

.axisToggle {
  transition: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
