// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
}

.non-workload {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: none;
  width: 100%;
  height: 100%;
}

/*.non-workload.message {*/
/*  padding-top: 15px;*/
/*  font-family: inherit;*/
/*}*/
.backlog-setting {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #cccccc;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 14px;
}

.user-field {
  padding-top: 12px;
}

input.input-wider {
  width: 8em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

:host ::ng-deep p-inputnumber.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #d82132;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
