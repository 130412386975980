// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-container{
  border:1px solid rgba(193, 190, 190, 0.49);
  justify-content:space-between;
  align-items:center;
  padding: 0 10px;
}

cxone-tabs {
  display:block;
  height: calc(100vh - 236px);
  /*border:1px solid #c1bebe;*/
  margin-top:16px;
}

p-multiSelect {
  min-width:250px;
}

.esp-tab {
  padding: 20px;
  height: 100%;
}

wfm-plan-loading-overlay {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 50;
}

.loading-error {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 180px);
  font-size: larger;
  box-shadow: 0 2px 6px #1f324d33;
  overflow: hidden;
}

span.err-msg {
  padding: 10px;
}
wfm-task-progress{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.no-op-hrs,
.no-fte-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: none;
  width: 100%;
  height: 100%;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
