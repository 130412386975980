// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* The input text */
#component-container {
  position: relative;
}
#component-container .search-icon, #component-container .clear-search-icon {
  color: #b8b8b8;
  position: absolute;
  right: 3%;
  top: 23%;
  cursor: pointer;
  fill: currentColor;
}
#component-container .data-grid-search-input {
  padding: 8px 36px 8px 8px !important;
  clear: both !important;
  height: 38px !important;
  border: 1px solid #dae2e8 !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 1px 0px !important;
  outline: none !important;
}
#component-container .data-grid-search-input::-ms-clear {
  display: none !important;
}

.b-inputtext {
  height: 40px;
  min-width: 220px;
  padding: 10px 10px 12px 10px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
  border: solid 1px #d6dee5;
  box-sizing: border-box;
  outline: none;
  border-radius: 0px;
  width: 100%;
}
.b-inputtext.error {
  border-color: #d82132;
}
.b-inputtext.disabled {
  background-color: #eee;
  border-color: #e0e7ed;
  color: #9b9ea0;
}
.b-inputtext.disabled:hover {
  border-color: #e0e7ed;
}
.b-inputtext:hover, .b-inputtext:active, .b-inputtextfocus {
  border-color: #237bc2;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
