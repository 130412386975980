// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  font-size: 14px;
  color: #787878;
  border: solid 1px #ced4da;
  width: 400px;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 10px;
}
input::-ms-clear {
  display: none;
}

input:read-only {
  background-color: #F8F8F8;
}

.input-with-icon {
  position: relative;
}

.input-with-icon div.icon {
  cursor: pointer;
  padding-right: 30px;
  color: #787878;
  border: 0;
  position: absolute;
  right: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}

.input-with-icon div.icon:active {
  right: -1px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
