// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding-left: 12px;
  padding-right: 12px;
  /* width: 100%; */
  display: block;
}

.text-container {
  border-top: 1px solid #d0d2d3;
}

.text-container span {
  color: #526B7A;
  opacity: 0.8;
  position: relative;
  height: 31px;
  display: flex;
  align-items: center;
  font-size: 12px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
