// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .breeze .p-tooltip.validation-error{
  max-width: 250px;
}
::ng-deep .breeze .p-tooltip.validation-error .p-tooltip-arrow{
/*  border: 1px solid #d0d2d3;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: white;
  border-width: 1px !important;*/
}
::ng-deep .breeze .validation-error.bs-popover-bottom.bs-popover-bottom-left{
  display: none;

}
::ng-deep .breeze .validation-error .message-text p{
  margin-bottom: 0;
}
::ng-deep .breeze .validation-error .message-text{
  font-family: "OpenSans", Arial, sans-serif;
  font-size: 12px;
  max-width: 300px;
  color: #2e2e2e;
  text-align: left;
  text-decoration: none;
  line-height: 15px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
