// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.historical-data-container {
  padding: 20px;
}

.page-header {
  flex: 0 0 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.table-list {
  border: 1px solid #c1bebe;
  background: white;
}

ag-grid-angular {
  width: 100%;
  height: 100%;
}

.ct-selector {
  width: 300px;
}

.datepicker-wrapper p-dropdown {
  min-width: 140px;
}

.datepicker-wrapper p-calendar {
  margin-left: 4px;
}

.datepicker-wrapper {
  display: flex;
  flex-direction: row;
}

:host ::ng-deep .datepicker-wrapper .p-component:disabled {
  background: #ececec;
}

@media screen and (max-width: 1200px) {
  .ct-selector {
    width: 200px;
  }
  .toolbar-vertical-item {
    min-width: 150px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
